import React from 'react'
import SectionTitle from '../title/SectionTitle';
import "./HowItWorks.scss";
import inspiration from "../../assets/images/icons/enquire/inspiration.svg";
import enquiry from "../../assets/images/icons/enquire/place-an-enquiry.svg";
import need from "../../assets/images/icons/enquire/what-you-need.svg";

const HowItWorks = () => {
  return (
    <div className="section-how-it-works section-padding">
      <SectionTitle 
        title="How It Works" 
        subTitle="At ArtsPic, we believe in a simple, user-friendly process to bring your creative projects to life:"/>
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-image">
                            <img className="img" src={inspiration} alt="inspiration"/>                            
                        </div>
                        <div className="card-title">Upload Your Inspiration</div>
                        <div className="card-text">
                            <p>Share a photo, design, or concept that reflects what you have in mind.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-image">
                            <img className="img" src={need} alt="need"/>                            
                        </div>
                        <div className="card-title">Tell Us What You Need</div>
                        <div className="card-text">
                            <p>Give us a detailed brief, including your goals, preferences, and any special requests.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-image">
                            <img className="img" src={enquiry} alt="enquiry"/>                            
                        </div>
                        <div className="card-title">Place an Enquiry</div>
                        <div className="card-text">
                            <p>Submit your request, and our team will provide a tailored solution quickly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks
