import Layout from "./Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Nopage from "./pages/nopage/Nopage";
import Services from "./pages/services/Services";
import ServiceList from "./pages/services/ServicesList";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
            <Route path="services" element={<Services />}>
              <Route index element={<ServiceList />} />
              <Route path=":categoryName" element={<ServiceList />} />
            </Route>
          <Route path="*" element={<Nopage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
