import React from 'react';
import "./footer.scss";
import footerLogo from "../../assets/images/logo-white.png"

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footerLogo">
              <img src={footerLogo} alt="footer logo"/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="contact-section">
              <p>Get In Touch</p>
              <div className="social">
                <span className="icon-set">
                  <i className="fa fa-facebook"/>
                </span>
                <span className="icon-set">
                  <i className="fa fa-twitter"/>
                </span>
                <span className="icon-set">
                  <i className="fa fa-instagram"/>
                </span>
                <span className="icon-set">
                  <i className="fa fa-linkedin"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
