import React, { useEffect } from 'react';
import ModalForm from './ModalForm';
import "./ModalForm.scss";
import { useSelector, useDispatch } from 'react-redux';
import { closePopup } from '../../features/popupSlice';

const BlankModal = () => {
     const dispatch = useDispatch();
     const isOpen = useSelector((state) => state.popup.isOpen);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]); 
    
    return (
        <>
            {isOpen && <div className="backdrop" onClick={() => dispatch(closePopup())} />}
            <div className={`modal-container ${isOpen ? 'active' : ''}`}>
                <div className="icon-set" onClick={() => dispatch(closePopup())}>
                    <i className="fa fa-close"/>
                </div>
                <div className="modal-title">Enquiry Form</div>
                <ModalForm />
            </div>
        </>
    );
};

export default BlankModal;
