import React from 'react';
import "./SectionTitle.scss";

const SectionTitle = ({title, subTitle}) => {
  return (
    <div className="section-title">
        <h3>{title}</h3>
        <p>{subTitle}</p>
    </div>
  )
}

export default SectionTitle
