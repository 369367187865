import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import StickyBox from "react-sticky-box";
import ServiceBanner from "../../assets/images/services/services-banner.jpg";
import ServiceBannerMobile from "../../assets/images/services/services-Mobile-banner.jpg";
import "./Services.scss";

const Services = () => {   
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1180);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1180);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

    return (
        <div className="product-list-page">
          <div className="category-image-container">
            {
              isMobile ? <img className="category-main-image" src={ServiceBannerMobile} alt="Services"/>
              : <img className="category-main-image" src={ServiceBanner} alt="Services"/>
            }
          </div>
          <div className="container">    
            <div className="row my-5">
              <nav aria-label="breadcrumb" className="border">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Services</a></li>
                  <li className="breadcrumb-item active" aria-current="page">All</li>
                </ol>
              </nav>
            </div>      
            <div className="row product-container">
              <StickyBox offsetTop={20} offsetBottom={20} className="filter-sidebar border">                
                  <div className="product-filter-container">
                    <div className="title">Services</div>
                    <div className="filter-list">
                      <NavLink to="/services/product-photography" className="filter-nav" end>Product Photography </NavLink>
                      <NavLink to="/services/ecommerce-photography" className="filter-nav" end>E-Commerce Photography </NavLink>
                      <NavLink to="/services/model-shoot" className="filter-nav" end>Model Shoot</NavLink>
                      <NavLink to="/services/socialmedia-creatives" className="filter-nav" end>Social Media Creatives</NavLink>
                      <NavLink to="/services/marketplace-creatives" className="filter-nav" end>Marketplace Creatives</NavLink>
                      <NavLink to="/services/360deg-jewelry-rendering" className="filter-nav" end>360° Jewelry Rendering </NavLink>
                      <NavLink to="/services/jewellery-retouching" className="filter-nav" end>Jewellery Retouching </NavLink>
                      <NavLink to="/services/ui-design" className="filter-nav" end>UI Design </NavLink>
                      <NavLink to="/services/photo-editing" className="filter-nav" end>Photo Editing </NavLink>
                      <NavLink to="/services/multimedia" className="filter-nav" end>Multimedia </NavLink>
                    </div>
                  </div>
              </StickyBox>
              <div className="product-list border pb-3">
                <div className="row">
                  <div className="mobile-filter border-bottom">
                    <div className="total">300 Items</div>
                    <div className="mobile-filter-nav">
                      <span className="icon-set">
                        <i className="fa fa-filter"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <Outlet/>
              </div>
            </div>
          </div>          
        </div>
    );
};

export default Services;
