import { configureStore } from '@reduxjs/toolkit';
import popupReducer from '../features/popupSlice';
import formReducer from '../features/formSlice';

export const store = configureStore({
  reducer: {
    popup: popupReducer,
    form: formReducer,
  },
});
