import React from "react";
import mImage1 from "../../assets/images/1.jpg";
import mImage2 from "../../assets/images/2.jpg";
import mImage3 from "../../assets/images/3.jpg";
import mImage4 from "../../assets/images/4.jpg";
import "./Services.scss";
import { useDispatch } from 'react-redux';
import { openPopup } from "../../features/popupSlice";
import { resetFormState } from "../../features/formSlice";

const ServicesList = () => {   
    const dispatch = useDispatch();
    const openModalForm = () => {
        resetFormState()
        dispatch(openPopup())
      };
  return (
    <>
    <div className="row">
        <div className="col-md-4">
        <div className="list-image-container">
            <img className="product-image" src={mImage1} alt="product-image"/>
            <div className="enquiry-fotm-btn" onClick={() => openModalForm()}>Enquire Now</div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="list-image-container">
            <img className="product-image" src={mImage2} alt="product-image"/>
            <div className="enquiry-fotm-btn" onClick={() => openModalForm()}>Enquire Now</div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="list-image-container">
            <img className="product-image" src={mImage3} alt="product-image"/>
            <div className="enquiry-fotm-btn" onClick={() => openModalForm()}>Enquire Now</div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="list-image-container">
            <img className="product-image" src={mImage4} alt="product-image"/>
            <div className="enquiry-fotm-btn" onClick={() => openModalForm()}>Enquire Now</div>
        </div>
        </div>
    </div>   
   
    </>
  )
}

export default ServicesList
