import React from 'react'
import "./ServiceList.scss";
import creativeSolutions from "../../assets/images/services/creative-solutions.jpg";
import productsPhotography from "../../assets/images/services/products-photography.jpg";
import uiuxdesign from "../../assets/images/services/ui-ux-design.jpg";
import marketplace from "../../assets/images/services/marketplace.jpg";
import SectionTitle from '../title/SectionTitle';
import ServiceSlider from './ServiceSlider';

const ServiceList = () => { 
  return (
    <div className="section-padding pb-0">
        <SectionTitle
        title="What We Do" 
        subTitle="At ArtsPic, we offer a wide range of creative services to elevate your brand:"/>
    
        <div className="section-service">   
            <div className="list-item">
                <img src={creativeSolutions} alt="service-1"/>
                <div className="heading-top white">
                    <h4>CREATIVE<span>SOLUTIONS</span></h4>
                </div>
            </div>
            <div className="list-item">
                <img src={productsPhotography} alt="service-2"/>
                <div className="heading-top">
                    <h4>Products <span>Photography</span></h4>
                </div>
            </div>
            <div className="list-item">
                <img src={uiuxdesign}  alt="service-3"/>
                <div className="heading-top white">
                    <h4>UI/Ux <span>Design & Development</span></h4>
                </div>
            </div>
            <div className="list-item">
                <img src={marketplace} alt="service-4"/>
                <div className="heading-top">
                    <h4>A+ Marketplace <span>Creatives</span></h4>
                </div>
            </div>
        </div>
        <ServiceSlider/>
    </div>
  )
}

export default ServiceList
