import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../features/popupSlice";
import { submitForm, resetFormState } from "../../features/formSlice";
import "./ModalForm.scss";

const ModalForm = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.form);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(submitForm(data));
    reset();
    dispatch(closePopup());
    dispatch(resetFormState());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Name</label>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          id="name"
          {...register("name", { required: "Name is required" })}
        />
        {errors.name && <small className="text-danger">{errors.name.message}</small>}
      </div>

      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email Address</label>
        <input
          type="email"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && <small className="text-danger">{errors.email.message}</small>}
      </div>

      <div className="mb-3">
        <label htmlFor="phone" className="form-label">Phone</label>
        <input
          type="text"
          className={`form-control ${errors.phone ? "is-invalid" : ""}`}
          id="phone"
          {...register("phone", {
            required: "Phone is required",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits",
            },
          })}
        />
        {errors.phone && <small className="text-danger">{errors.phone.message}</small>}
      </div>

      <div className="mb-3">
        <label htmlFor="message" className="form-label">Your Message</label>
        <textarea
          className={`form-control ${errors.message ? "is-invalid" : ""}`}
          id="message"
          {...register("message", { required: "Message is required" })}
        ></textarea>
        {errors.message && <small className="text-danger">{errors.message.message}</small>}
      </div>

      <div className="mb-3 form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.tandc ? "is-invalid" : ""}`}
          id="tandc"
          {...register("tandc", { required: "You must agree to the terms and conditions" })}
        />
        <label className="form-check-label" htmlFor="tandc">
          I agree to receiving marketing information regarding services and products as well as invitations to trade shows and events organised by Jewelry Cad on Demand by means of the personal data provided.
        </label>
        {errors.tandc && <small className="text-danger">{errors.tandc.message}</small>}
      </div>

      <button type="submit" className="btn btn-primary enquireNowBtn" disabled={loading}>
        {loading ? "Submitting..." : "Enquire Now"}
      </button>
      {success && <p className="text-success mt-3">Form submitted successfully!</p>}
      {error && <p className="text-danger mt-3">Error: {error}</p>}
    </form>
  );
};

export default ModalForm;
