import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./SlideMenu.scss";
import initial from "../../assets/images/logo-initial.png";
import { openPopup } from '../../features/popupSlice';
import { NavLink } from 'react-router-dom';

const SlideMenu = ({ open, handleToggle }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.popup.isOpen);

  const handleOpenPopup = () => {
    handleToggle(false);
    dispatch(openPopup());
  };

  useEffect(() => {
    if (isOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
    return () => {
        document.body.style.overflow = '';
    };
}, [isOpen]);

  return (
    <>
      {open && (
        <div className="backdrop" onClick={() => handleToggle(false)}></div>
      )}
      <div className={`popup-sidebar mobile ${open ? 'active' : ''}`}>
        <div className="menu-close">
          <div className="icon-set" onClick={() => handleToggle(false)}>
            X
          </div>
        </div>
        <div className="sidebar-content">
          <ul className="nav-center">
            <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" end >Home</NavLink>
            <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/about" end >About Us</NavLink>
            <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/3d-gallery" end >3D Gallery</NavLink>
            <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/enquire-here" end >Enquire Here</NavLink>
          </ul>
          <div className="sidebar-heading">
            <h3>Services</h3>
          </div>
          <div className="service-box">
            <ul>             
              <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" >creative solutions</NavLink>
              <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" >PROJECT OVERVIEW</NavLink>
              <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" >EXPLORE creativity</NavLink>
              <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" >DIGITAL analysis</NavLink>
              <NavLink className="mobile-nav"  onClick={() => handleToggle(false)} to="/" >Product Photography</NavLink>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideMenu;
