import React from 'react'
import Slider from '../../components/slider/Slider'
import HowItWorks from '../../components/howItWorks/HowItWorks'
import CompareSlider from '../../components/compareSlider/CompareSlider'
import ServiceList from '../../components/serviceList/ServiceList'
import Gallery from '../../components/gallery/Gallery';
import homeVideo from "../../assets/video/video.mp4";
import "./Home.scss";
import ThreeDSlider from "../../components/serviceList/ThreeDSlider"

const Home = () => {
  return (
    <div className="homepage">
      <div className="homeVideo">
        <div className="video-content">
          <h1>FINE JEWELRY VISUAL CONTENT</h1>
          <h2>everything you need to sell jewelry online.</h2>
        </div>
        <div className="video-wrap video-full videoContainerMobileAdapt fullHeight">
          <video loop muted autoplay playsInline preload="auto" className="video">
            <source src={homeVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      {/* <Slider/> */}
      <HowItWorks/> 
      <CompareSlider/>
      <ServiceList/>
      <ThreeDSlider/>
      <Gallery/>
    </div>
  )
}

export default Home
