import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import "./CompareSlider.scss";
import before from "../../assets/images/works/before.jpg";
import after from "../../assets/images/works/after.jpg";
import after2 from "../../assets/images/works/after-2.jpg";
import before2 from "../../assets/images/works/before-2.jpg";

const CompareSlider = () => {
    const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
  return (
    <div className="section-compare-slider"> 
        <div className="compareBox">
          <ReactCompareSlider
              onlyHandleDraggable={isTouchDevice}
              itemOne={<ReactCompareSliderImage src={before} srcSet={before} alt="Image one" />}
              itemTwo={<ReactCompareSliderImage src={after} srcSet={after} alt="Image two" />}
              className="sliderControl"
          />
          <div className="title">Jewellery<span>Rendering</span></div>
        </div>
        <div className="compareBox">
          <ReactCompareSlider
              onlyHandleDraggable={isTouchDevice}
              itemOne={<ReactCompareSliderImage src={after2} srcSet={after2} alt="Image one" />}
              itemTwo={<ReactCompareSliderImage src={before2} srcSet={before2} alt="Image two" />}
              className="sliderControl"
          />
          <div className="title">Photo<span>Editing</span></div>
        </div>       
    </div>
  )
}

export default CompareSlider
