import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ServiceList.scss";
import model1 from "../../assets/images/three-d/model-1.jpg";
import model2 from "../../assets/images/three-d/model-2.jpg";

function ServiceSlider() {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div className="sliderContainer">
          <Slider {...settings}>
            <div className="item-container">
              <div className="item">
                <img src={model1} alt="model1"/>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <img src={model2} alt="model2"/>
              </div>
            </div>            
          </Slider>
        </div>
      );
    }
export default ServiceSlider;
