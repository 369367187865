import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./SlideMenu.scss";
import initial from "../../assets/images/logo-initial.png";
import { openPopup } from '../../features/popupSlice';
import { Link } from 'react-router-dom';

const SlideMenu = ({ open, handleToggle }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.popup.isOpen);

  const handleOpenPopup = () => {
    handleToggle(false);
    dispatch(openPopup());
  };

  useEffect(() => {
    if (isOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
    return () => {
        document.body.style.overflow = '';
    };
}, [isOpen]);

  return (
    <>
      {open && (
        <div className="backdrop" onClick={() => handleToggle(false)}></div>
      )}
      <div className={`popup-sidebar ${open ? 'active' : ''}`}>
        <div className="menu-close">
          <div className="icon-set" onClick={() => handleToggle(false)}>
            <span></span>
            <span className="big"></span>
            <span></span>
          </div>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-heading">
            <img src={initial} alt="initial logo"/>
            <h3>Services</h3>
          </div>
          <div className="service-box">
            <ul>
              <li><Link onClick={() => handleToggle(false)} to="/services/product-photography" className="filter-nav" end>Product Photography </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/ecommerce-photography" className="filter-nav" end>E-Commerce Photography </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/model-shoot" className="filter-nav" end>Model Shoot</Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/socialmedia-creatives" className="filter-nav" end>Social Media Creatives</Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/marketplace-creatives" className="filter-nav" end>Marketplace Creatives</Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/360deg-jewelry-rendering" className="filter-nav" end>360° Jewelry Rendering </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/jewellery-retouching" className="filter-nav" end>Jewellery Retouching </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/ui-design" className="filter-nav" end>UI Design </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/photo-editing" className="filter-nav" end>Photo Editing </Link></li>
              <li><Link onClick={() => handleToggle(false)} to="/services/multimedia" className="filter-nav" end>Multimedia </Link></li>
            </ul>
          </div>
          <div className="contact-info">
            <h3>Contact Us</h3>
            <ul>
              <li><i className="fa fa-phone"/>+918689855985</li>
              <li><i className="fa fa-envelope-o"/>artspic2025@gmail.com</li>
            </ul>
          </div>
          <div className="contact-btn" onClick={() => handleOpenPopup()}>Enquire Here</div>
        </div>
      </div>
    </>
  );
};

export default SlideMenu;
