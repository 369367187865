import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import "./navigation.scss";
import logo from "../../assets/images/logo-white.png";
import SlideMenu from '../slideMenu/SlideMenu';
import { closePopup } from '../../features/popupSlice';
import MobileMenu from "../slideMenu/MobileMenu";
import BlankModal from "../modal/BlankModal";


const Navigation = () => { 
  const [open, setOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1100);
  const handleToggle = (value) => {
    setOpen(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1180);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <>
          <header className="header mobile">
            <div className="nav-left">
                <div className="menu">
                  <div className="icon-set" onClick={() => handleToggle(true)}>
                    <span></span>
                    <span className="big"></span>
                    <span></span>
                  </div>
                </div>
              </div>
            <div className="logo">
              <NavLink to="/" end><img src={logo} alt="artspic-logo"/></NavLink>
            </div>
          </header>
          <MobileMenu open={open} handleToggle={handleToggle} />
        </>
      ) : (
        <>
          <header className="header">
            <div className="logo">
            <NavLink to="/" end><img src={logo} alt="artspic-logo"/></NavLink>
            </div>
            <ul className="nav-center">
              <NavLink className="top-nav"  to="/" end >Home</NavLink>
              <NavLink className="top-nav"  to="/about" end >About Us</NavLink>
              <NavLink className="top-nav"  to="/services" >Services</NavLink>
              <NavLink className="top-nav"  to="/3d-gallery" end >3D Gallery</NavLink>
              <NavLink className="top-nav"  to="/enquire-here" end >Enquire Here</NavLink>
            </ul>
            <div className="nav-right">
              <div className="menu">
                <div className="icon-set" onClick={() => handleToggle(true)}>
                  <span></span>
                  <span className="big"></span>
                  <span></span>
                </div>
              </div>
            </div>
          </header>
          <SlideMenu open={open} handleToggle={handleToggle} />
          <BlankModal/>
        </>
      )}
    </>
  )
}

export default Navigation
