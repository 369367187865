import React from 'react';
import { Link } from 'react-router-dom';
import "./Gallery.scss";
import SectionTitle from '../title/SectionTitle';
import ringVideo from "../../assets/video/Rings.mp4";
import bangleVideo from "../../assets/video/Bangle.mp4";
import g1 from "../../assets/images/gallery/1.jpg";
import g2 from "../../assets/images/gallery/2.jpg";
import g3 from "../../assets/images/gallery/3.jpg";
import g4 from "../../assets/images/gallery/4.jpg";
import g5 from "../../assets/images/gallery/5.jpg";
import g6 from "../../assets/images/gallery/6.jpg";
import g8 from "../../assets/images/gallery/8.jpg";

const Gallery = () => {
  return (
    <div className="section-gallery section-padding">
      <SectionTitle title="Product Gallery" subTitle="Product Gallery"/>
        <div className="widget-container">
            <div className="portfolio-box grid-style"> 
                <div className="portfolio-box-item  box-square">
                    <img decoding="async" src={g1} alt="ring" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Jar Candle </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>
                </div> 
                <div className="portfolio-box-item  box-tall">
                    <img decoding="async" src={g2} alt="earring" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Visiting Card </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>                             
                </div> 
                <div className="portfolio-box-item  box-square">
                    <img decoding="async" src={g3} alt="model" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Headphone </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>
                </div> 
                <div className="portfolio-box-item box-tall">
                    <img decoding="async" src={g4} alt="model" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Headphone </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>
                </div>                 
                <div className="portfolio-box-item  box-tall">                    
                    <img decoding="async" src={g5} alt="chain" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                        <div className="portfolio-box-content-inner"> 
                            <h4 className="portfolio-title">Tote Bag </h4>  <p className="portfolio-category">Mockup</p>
                        </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>                             
                </div> 
                
                <div className="portfolio-box-item  box-tall">
                    <video loop muted autoPlay playsInline preload="auto" className="video">
                        <source src={ringVideo} type="video/mp4" />
                    </video>
                </div> 
                <div className="portfolio-box-item  box-square">
                    <img decoding="async" src={g6} alt="pendant" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Cosmetic </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>                             
                </div> 
                <div className="portfolio-box-item  box-square">
                    <img decoding="async" src={g8} alt="car" data-no-retina=""/> 
                    <div className="portfolio-box-content">
                            <div className="portfolio-box-content-inner"> 
                                <h4 className="portfolio-title">Coffee Cup </h4>  <p className="portfolio-category">Mockup</p>
                            </div>
                    </div>
                    <Link className="portfolio-link" to="/services"></Link>
                </div>
            </div>				
        </div>
    </div>
  )
}

export default Gallery
