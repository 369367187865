import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ServiceList.scss";
import productphotography from "../../assets/images/icons/service/productphotography.svg";
import ecommercephotography from "../../assets/images/icons/service/e-commercephotography.svg";
import modeling from "../../assets/images/icons/service/modeling.svg";
import socialmedia from "../../assets/images/icons/service/socialmedia.svg";
import marketplacecreatives from "../../assets/images/icons/service/marketplacecreatives.svg";
import jewelryrendering from "../../assets/images/icons/service/jewelryrendering.svg";
import jewelleryretouching from "../../assets/images/icons/service/jewelleryretouching.svg";
import uidesign from "../../assets/images/icons/service/uidesign.svg";
import photoediting from "../../assets/images/icons/service/photoediting.svg";
import multimedia from "../../assets/images/icons/service/multimedia.svg";

function ServiceSlider() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow:5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
          }
        ]
      };
      return (
        <div className="slider-container">
          <Slider {...settings}>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={productphotography} alt="Product Photography"/>
                </div>
                <div className="item-title">Product Photography</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={ecommercephotography} alt="E-Commerce Photography"/>
                </div>
                <div className="item-title">E-Commerce Photography</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={modeling} alt="Model Shoot"/>
                </div>
                <div className="item-title">Model Shoot</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={socialmedia} alt="Social Media Creatives"/>
                </div>
                <div className="item-title">Social Media Creatives</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={marketplacecreatives} alt="Marketplace Creatives"/>
                </div>
                <div className="item-title">Marketplace Creatives</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={jewelryrendering} alt="360° Jewelry Rendering"/>
                </div>
                <div className="item-title">360° Jewelry Rendering</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={jewelleryretouching} alt="Jewellery Retouching"/>
                </div>
                <div className="item-title">Jewellery Retouching</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={uidesign} alt="UI/UX Design"/>
                </div>
                <div className="item-title">UI Design</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={photoediting} alt="Photo Editing"/>
                </div>
                <div className="item-title">Photo Editing</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item">
                <div className="icon">
                    <img src={multimedia} alt="Multimedia"/>
                </div>
                <div className="item-title">Multimedia</div>
              </div>
            </div>
          </Slider>
        </div>
      );
    }
export default ServiceSlider;
